<template>
  <div class="container">
    <div class="section">
      <div class="title" style="color: red;">热门城市</div>
      <span v-for="item in hotCities" :key="item.id">
        <a class="a-btn" @click="onChangeCity(item)" style="margin-left:20px;">{{item.city_name}}</a>
      </span>
    </div>
    <div class="section">
      <div class="title">城市选择</div>
      <Select v-model="selProvinceID" placeholder="省份" style="width:160px; margin: 0 20px;">
        <Option v-for="item in provinces" :value="item.province_id" :key="item.province_id">{{ item.province_name }}</Option>
      </Select>
      <Select v-model="selCityID" placeholder="城市" @on-change="onSelectCityChange" style="width:160px">
        <Option v-for="item in optCities" :value="item.city_id" :key="item.city_id">{{ item.city_name }}</Option>
      </Select>
    </div>
    <div class="content">
      <div class="title">全国主要城市</div>
      <div class="box">
        <div v-for="item in allCities" :key="item.letter" class="letter-box">
          <div class="letter-hd"><span class="letter">{{item.letter}}</span></div>
          <div class="letter-bd">
            <div v-for="province in item.provinces" :key="province.province_id" class="province-box">
              <div class="province">{{province.province_name}}</div>
              <div class="city-box">
                <a v-for="city in province.cities" :key="city.city_id" class="city" @click="onChangeCity(city)">{{city.city_name}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { getChangeCityData } from '@/api/city.js'

export default {
  name: 'ChangeCity',
  data() {
    return {
      selProvinceID: null,
      selCityID: null,

      hotCities: null,
      provinces: null,
      allCities: null
    }
  },
  computed: {
    optCities() {
      if (this.selProvinceID != null) {
        const len = this.provinces.length
        for (let i = 0; i < len; i++) {
          if (this.selProvinceID == this.provinces[i].province_id) {
            return this.provinces[i].cities
          }
        }
      }
      return null
    }
  },
  watch: {
    $route(newRoute) {
      console.log('newRoute: ', newRoute)
      // console.log('oldRoute: ', oldRoute)
    },
    optCities(newVal) {
      if (newVal) {
        // this.selCityID = this.optCities[0].city_id
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(['setCityAction']),
    async initData() {
      try {
        const res = await getChangeCityData({city_id: this.cityId})
        this.hotCities = res.content.hot_cities
        this.provinces = res.content.provinces
        this.allCities = res.content.all_cities
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    onSelectCityChange(val) {
      const len = this.optCities ? this.optCities.length : 0
      for (let i = 0; i < len; i++) {
        const item = this.optCities[i]
        if (item.city_id == val) {
          this.setCityAction({cityId: item.city_id, city: item.city_name})
          this.onChangeCity(item)
        }
      }
    },
    onChangeCity(city) {
      // console.log(city)
      this.setCityAction({cityId: city.city_id, city: city.city_name})
      this.$router.push({ name: this.$config.homeName})
      /*
      const backPage = this.$route.params.backPage
      if (backPage) {
        this.$router.push({ name: backPage })
      } else {
        this.$router.push({ name: this.$config.homeName})
      } */
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 30px;
}
.section {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .title {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    margin-right: 20px;
  }
}
.content {
  .title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }
  .box {
    margin-top: 10px;
    border: solid 1px #eee;
    .letter-box {
      display: flex;
      border-bottom: solid 1px #eee;
      .letter-hd {
        width: 40px;
        display: flex;
        flex-shrink: 0;
        color: #999;
        background-color: #f5f5f5;
        border-right: solid 1px #eee;
        .letter {
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          margin: auto;
        }
      }
      .letter-bd {
        flex: 1;
        .province-box {
          position: relative;
          display: flex;
          border-bottom: solid 1px #eee;
          flex: 1;
          &:hover{
            background-color: #f5f5f5;
          }
          .province {
            box-sizing: border-box;
            width: 80px;
            padding: 10px 0 10px 20px;
            color: #333;
            font-size: 16px;
            border-right: solid 1px #eee;
          }
          .city-box {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
            .city {
              flex-shrink: 0;
              line-height: 20px;
              color: #303;
              font-size: 14px;
              margin: 2px 12px;
              &:hover{
                color: #c00;
              }
            }
          }
        }
        .province-box:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}

.a-btn {
  font-size: 15px;
  color:#333;
  &:hover{
    color: #c00;
  }
}
</style>
